import { defineStore } from 'pinia'
import { STORE_ID } from '@/constants/store'
import type { GiftCardsState as State } from '@/types/store'

const initialState = (): State => ({
  wizard: {
    activeStep: 1,
    giftGardValues: {
      skin: undefined,
      personalisation: undefined
    }
  },
  data: {
    skinGroups: []
  }
})

const useGiftCardsStore = defineStore(STORE_ID.GIFT_CARDS, {
  state: initialState,
  persist: {
    storage: persistedState.cookiesWithOptions({
      sameSite: 'strict',
      maxAge: 60 * 60 * 6
    }),
    paths: ['wizard']
  }
})
export default useGiftCardsStore
